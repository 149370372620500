<template>
  <div>
    <div class="toggleable" @click="Toggle()">
      <!-- <div class="square"></div> -->
      <b-icon icon="chevron-down" v-if="!show"></b-icon>
      <b-icon icon="chevron-up" v-else></b-icon>
      <h2>{{ $t("products." + obj.name + ".name") }}</h2>
      <!-- <b-icon icon="chevron-down" font-scale="1.2"></b-icon> -->
    </div>
    <div class="grid" v-show="show">
      <div class="grid-item" v-for="item in obj.all" :key="item.name">
        <img :src="item.img" alt="" />
        <h3>{{ $t("products." + obj.name + "." + item.name + ".name") }}</h3>
        <h4>{{ $t("products." + obj.name + "." + item.name + ".des") }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["obj"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    Toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
.b-icon {
  height: 33px;
  width: 23px;
  background-color: #2c3e50;
  padding: 2px;
  color: white;
  /* color: grey; */
}
.toggleable {
  text-align: left;
  margin-left: 15%;
  padding: 5px;
  cursor: pointer;
}
.toggleable :hover {
  text-decoration: underline 1px;
}
.square {
  background-color: #2c3e50;
  width: 20px;
  height: 33px;
  display: inline-block;
}
h2 {
  display: inline-block;
  padding: 10px;
  /* font-family: Cloud; */
  font-size: 28px;
}
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid-item {
  padding: 5%;
}
img {
  height: 180px;
}
h3 {
  margin: 2%;
}
h4 {
  font-family: cloud;
  width: 275px;
  margin: auto;
}
@media screen and (max-width: 800px) {
  .toggleable {
    margin-left: 0%;
  }
  img {
    height: 100px;
  }
  h4 {
    width: inherit;
  }
}
</style>

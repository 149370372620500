<template>
  <div>
    <h1 class="p-3">{{ $t("products.name") }}</h1>
    <Product v-for="dir in products" :key="dir.name" :obj="dir"></Product>
  </div>
</template>

<script>
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
import Product from "@/components/allproducts/Product.vue";
export default {
  components: { Product },
  data() {
    return {
      products: {
        wasabi: {
          name: "wasabi",
          all: {
            sauce: {
              img: "",
              name: "sauce",
            },
            tube: {
              img: "",
              name: "tube",
            },
            powder: {
              img: "",
              name: "powder",
            },
            flavor: {
              img: "",
              name: "flavor",
            },
            sachet: {
              img: "",
              name: "sachet",
            },
            frozen_sachet: {
              img: "",
              name: "frozen_sachet",
            },
            frozen_pack: {
              img: "",
              name: "frozen_pack",
            },
            raw: {
              img: "",
              name: "raw",
            },
          },
        },

        mayo: {
          name: "mayo",
          all: {
            wasabi: {
              img: "",
              name: "wasabi",
            },
            pepper: {
              img: "",
              name: "pepper",
            },
            sriracha: {
              img: "",
              name: "sriracha",
            },
            mayo: {
              img: "",
              name: "mayo",
            },
          },
        },

        mustard: {
          name: "mustard",
          all: {
            flour: {
              img: "",
              name: "flour",
            },
            yellow: {
              img: "",
              name: "yellow",
            },
            wholegrain: {
              img: "",
              name: "wholegrain",
            },
            oil: {
              img: "",
              name: "oil",
            },
          },
        },

        oil: {
          name: "oil",
          all: {
            horseradish: {
              img: "",
              name: "horseradish",
            },
            mustard: {
              img: "",
              name: "mustard",
            },
          },
        },

        sauce: {
          name: "sauce",
          all: {
            pakchi: {
              img: "",
              name: "pakchi",
            },
            chilli: {
              img: "",
              name: "chilli",
            },
            basil: {
              img: "",
              name: "basil",
            },
            chicken: {
              img: "",
              name: "chicken",
            },
            wasabi: {
              img: "",
              name: "wasabi",
            },
            teriyaki: {
              img: "",
              name: "teriyaki",
            },
            tamarin: {
              img: "",
              name: "tamarin",
            },
            shoyu: {
              img: "",
              name: "shoyu",
            },
          },
        },
      },
    };
  },

  created() {
    Object.keys(this.products).forEach((dir) =>
      Object.keys(this.products[dir].all).forEach((item) =>
        getDownloadURL(ref(storage, `products/${dir}/${item}.png`)).then(
          (url) => {
            this.products[dir].all[item].img = url;
          }
        )
      )
    );
  },
};
</script>
